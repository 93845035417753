.bt-footer-bar {
  margin-top: 3rem;
  background: var(--dark-primary-color);
}

.bt-footer-bar-bottom {
  background: var(--ultra-dark-color);
}

.terms-policy {
  cursor: pointer;
}

.terms-policy:hover {
  font-weight: 500;
  text-decoration: underline;
}

.designer-redirect {
  text-decoration: none;
  color: #fff;
}

.designer-redirect:hover {
  text-decoration: underline;
  color: white;
}

#title {
  font-size: larger;
  font-weight: 800;
  color: var(--primary-color);
}

#sub-title {
  font-weight: 500;
}

#subtitle {
  font-weight: 500;
}

#subtitle a {
  text-decoration: none;
  color: #fff;
}
