.services {
  margin-top: 4rem;
}

.grid-container {
  display: grid;
  align-items: center;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(340px, 2fr));
  grid-gap: 40px;
}
