/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;800&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Nunito", sans-serif;
  scroll-behavior: smooth;
  transition: 800ms all;
}
html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
:root {
  --primary-color: #e76f51;
  --secondary-color: #f4a261;
  --faint-color: #e9c46a;
  --special-color: #2a9d8f;
  --dark-primary-color: #264653;
  --ultra-dark-color: hsl(198, 37%, 14%);
}

.section-title {
  font-size: xx-large;
  font-weight: 700;
  color: var(--primary-color);
}
