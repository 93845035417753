@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;800&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

* {
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Nunito", sans-serif;
  scroll-behavior: smooth;
  transition: 800ms all;
}
html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
:root {
  --primary-color: #e76f51;
  --secondary-color: #f4a261;
  --faint-color: #e9c46a;
  --special-color: #2a9d8f;
  --dark-primary-color: #264653;
  --ultra-dark-color: hsl(198, 37%, 14%);
}

.section-title {
  font-size: xx-large;
  font-weight: 700;
  color: #e76f51;
  color: var(--primary-color);
}

.about {
  margin-top: 2rem;
}

.about-img {
  width: 470px;
  height: 370px;
}

.description {
  font-size: x-large;
  text-align: justify;
}

.image-content {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 727px) {
  .about-img {
    width: 350px;
    height: 250px;
  }

  .image-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bt-footer-bar {
  margin-top: 3rem;
  background: var(--dark-primary-color);
}

.bt-footer-bar-bottom {
  background: var(--ultra-dark-color);
}

.terms-policy {
  cursor: pointer;
}

.terms-policy:hover {
  font-weight: 500;
  text-decoration: underline;
}

.designer-redirect {
  text-decoration: none;
  color: #fff;
}

.designer-redirect:hover {
  text-decoration: underline;
  color: white;
}

#title {
  font-size: larger;
  font-weight: 800;
  color: var(--primary-color);
}

#sub-title {
  font-weight: 500;
}

#subtitle {
  font-weight: 500;
}

#subtitle a {
  text-decoration: none;
  color: #fff;
}

#nav-link-text {
  color: var(--special-color);
  font-weight: 900;
}

#nav-link-text:hover {
  color: var(--primary-color);
  font-weight: bold;
  transition: 0.3s all;
}

#social-media-link {
  color: var(--secondary-color);
  cursor: pointer;
}

#social-media-link:hover {
  color: var(--primary-color);
  transition: 300ms all;
}

.lang {
  background-color: #f4a261;
}

.hero {
  position: relative;
  transition: 85ms all;
}

.banner {
  width: 100%;
  height: 650px;
}

.banner-content {
  position: absolute;
  margin-top: -450px;
}

.banner-content span {
  font-weight: bolder;
  font-size: xxx-large;
  text-shadow: 1px 1px #fff;
  color: var(--primary-color);
}

.banner-btn {
  position: absolute;
  margin-top: -280px;
}

.contact-btn {
  background-color: var(--secondary-color) !important;
  outline-color: none !important;
  border: none !important;
}

.contact-btn:hover {
  background-color: var(--primary-color) !important;
}

@media screen and (max-width: 720px) {
  .banner {
    height: 450px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .banner-content {
    margin-top: -350px;
  }

  .banner-btn {
    margin-top: -180px;
  }
}

.leaflet-container {
  height: 50vh;
  margin-top: 1.5rem;
}

.projects {
  margin-top: 4rem;
}

.grid-container {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(340px, 2fr));
  grid-gap: 40px;
}

.services {
  margin-top: 4rem;
}

.grid-container {
  display: grid;
  align-items: center;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(340px, 2fr));
  grid-gap: 40px;
}

