#nav-link-text {
  color: var(--special-color);
  font-weight: 900;
}

#nav-link-text:hover {
  color: var(--primary-color);
  font-weight: bold;
  transition: 0.3s all;
}

#social-media-link {
  color: var(--secondary-color);
  cursor: pointer;
}

#social-media-link:hover {
  color: var(--primary-color);
  transition: 300ms all;
}

.lang {
  background-color: #f4a261;
}
