.about {
  margin-top: 2rem;
}

.about-img {
  width: 470px;
  height: 370px;
}

.description {
  font-size: x-large;
  text-align: justify;
}

.image-content {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 727px) {
  .about-img {
    width: 350px;
    height: 250px;
  }

  .image-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
