.hero {
  position: relative;
  transition: 85ms all;
}

.banner {
  width: 100%;
  height: 650px;
}

.banner-content {
  position: absolute;
  margin-top: -450px;
}

.banner-content span {
  font-weight: bolder;
  font-size: xxx-large;
  text-shadow: 1px 1px #fff;
  color: var(--primary-color);
}

.banner-btn {
  position: absolute;
  margin-top: -280px;
}

.contact-btn {
  background-color: var(--secondary-color) !important;
  outline-color: none !important;
  border: none !important;
}

.contact-btn:hover {
  background-color: var(--primary-color) !important;
}

@media screen and (max-width: 720px) {
  .banner {
    height: 450px;
    width: fit-content;
  }

  .banner-content {
    margin-top: -350px;
  }

  .banner-btn {
    margin-top: -180px;
  }
}
